<template>
	
	<div class="cont" id="p09"  style="width: 100%; height: 100%; position: fixed; left: 0; top: 0; background: none; z-index: 9999;">
		<div class="popup-list" style="width: 100%; height: 100%; position: fixed; left: 0; top: 0;">
			<div class="popup type02" style="width: 550px; margin: 10% auto; transform: none; overflow: hidden; position: relative !important; z-index: 9999;">
				<div class="p-header">
					<div class="title">{{ program.name }}</div>
				</div>
				<div class="p-body">
					<div class="c01">
						<div class="pc-header"><span class="title">상태</span></div>
						<div class="pc-body">
							<div class="item_list">
								<div class="item">
									<div class="title">대리점</div>
									<div class="desc">{{ item.businessName }}</div>
								</div>
								<div class="item">
									<div class="title">현재 상태</div>
									<div class="desc on"> <!-- 상태 on/off = class "on"-->
										<span class="status_on">{{ item.statusName }}</span>
										<span class="status_off">해지 (2020.12.01)</span>
									</div>
								</div>
								<div class="item reason hide"> <!-- display 숨김처리 class "hide" -->
									<div class="title">사유</div>
									<div class="desc">
										계약만료
									</div>
								</div>
								<div class="item slt">
									<div class="title">변경</div>
									<div class="desc">
										<select class="select_type"
											v-model="item.status"
											>
											<option 
												v-for="(item, index) in codes.posStatus"
												:key="index"
												:value="item.code"
											>{{ item.codeName }}</option>
										</select>
									</div>
								</div>
								<div class="item trea input_reason"> <!-- 사용정지, 해지 선택시 class "active" -->
									<div class="title">사유</div>
									<div class="desc">
										<textarea
											v-model="itemStatus.reason"
										></textarea>
									</div>
								</div>
							</div>
							<div class="p-text hide"> <!-- display 숨김처리 class "hide" -->
								사용정지 시 모든 서비스 접속이 차단됩니다.
							</div>
						</div>
					</div>
				</div>
				<div class="p-button">
					<v-row>
						<v-col>
							<v-btn
								class="success"
								style="background: #00bfa5;"
								block tile
								@click="save"
							>변경</v-btn>
						</v-col>
						<v-col>
							<v-btn
								class=""
								style="background: #bbb;"
								block tile
								@click="close"
							>취소</v-btn>
						</v-col>
					</v-row>
				</div>
			</div>
		</div>
		
	</div>

</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	
	export default{
		name: 'MerchantsTerminalPopupStatus'
		,props: ['itemStatus', 'codes']
		,data: function(){
			return {
				program: {
					name: '단말기 상태 변경'
				}
				,item: {
				}
			}
		}
		,methods: {
			getData: async function(){
			
				try{
					let result = await Axios({
						method: 'get'
						,url: '/merchants/terminal/' + this.itemStatus.pgTid + '/status'
						,authorize: true
					})
					
					if(result.success){
						this.item = result.data.content
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,close: function(){
				this.$emit('setOverlay', false)
			}
			,save: async function(){
				try{
					let result = await Axios({
						method: 'post'
						,url: '/merchants/terminal/status'
						,data: this.item
						,authorize: true
					})
					
					if(result.success){
						this.$emit('setNotify', { type: 'success', message: result.data.message })
						await this.$emit('setOverlay', false)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.data.message })
					}
				}catch(E){
					console.log(E)
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
		}
		,created: function(){
			this.getData()
		}
		,watch: {
		}
	}
</script>




