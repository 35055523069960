<template>

	<div class="cont" id="p18">
		<div class="page-title">{{ program.name }}</div>

		<div class="c01">
			<div class="left">
				<div class="s01">
					<div class="c-item w100">
						<div class="title">등록일</div>
						<div class="content flex">
							<div class="cont-toggle">
								<div class="btn-toggle"
									:class="{ active: dateType == 'd'}"
									@click="setSearchDate('d')"
								>일간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'w'}"
									@click="setSearchDate('w')"
								>주간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'm'}"
									@click="setSearchDate('m')"
								>월간</div>
								<div class="btn-toggle"
									:class="{ active: dateType == 'c'}"
									@click="setSearchDate('c')"
								>선택</div>
							</div>
							<div class="cont-date">
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="시작일"
										v-model="search.inquiryStartDate"
										@click="setSearchDate('c'); search.inquiryStartDate = ''; datePicker.start.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.start.view"
										v-model="search.inquiryStartDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 460px; border: 1px solid #bbb;"
										@change="datePicker.start.view = false"
									></v-date-picker>
								</div>
								<span>~</span>
								<div class="parent-datepicker">
									<input type="text" class="datepicker"
										placeholder="종료일"
										v-model="search.inquiryEndDate"
										@click="setSearchDate('c'); search.inquiryEndDate = ''; datePicker.end.view = true"
										readonly
									>
									<v-date-picker
										v-if="datePicker.end.view"
										v-model="search.inquiryEndDate"
										no-title
										scrollable
										style="position: absolute; top: 120px; left: 660px; border: 1px solid #bbb;"
										@change="datePicker.end.view = false"
									></v-date-picker>
								</div>
							</div>
							<div class="notice">최대 3개월 이내에서 조회할 수 있습니다.</div>
						</div>
					</div>
					<div class="c-item">
						<div class="title">단말기 상태</div>
						<div class="content">
							<select
								v-model="search.status"
								@change="getData"
							>
								<option value="">단말기 상태를 선택하세요</option>
								<option 
									v-for="(item, index) in codes.posStatus"
									:key="index"
									:value="item.code"
								>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">단말기 종류</div>
						<div class="content">
							<select
								v-model="search.posType"
								@change="getData"
							>
								<option 
									v-for="(item, index) in codes.posType"
									:key="index"
									:value="item.code"
								>{{ item.codeName }}</option>
							</select>
						</div>
					</div>
					<div class="c-item">
						<div class="title">검색어</div>
						<div class="content">
							<input type="text" placeholder="가맹점명, 모델명 또는 단말기 번호를 입력하세요."
								v-model="search.keyword"
							>
						</div>
					</div>
				</div>
				<div class="s02">
					<a @click="getData"><div class="btn-search">조회</div></a>
					<a @click="clear"><div class="btn-reset">초기화</div></a>
					<router-link :to="{ name: 'MerchantsTerminalItem' }"><div class="btn-reset">단말기 등록</div></router-link>
				</div>
			</div>
		</div>

		<div class="c02">
			<div class="table type04">
				<div class="item-header">
					<div class="item">
						<div class="td">NO</div>
						<div class="td">등록일자</div>
						<div class="td">가맹점명</div>
						<div class="td">모델명</div>
						<div class="td">단말기 번호</div>
						<div class="td">단말기 종류</div>
						<div class="td">상태</div>
					</div>
				</div>
				<div class="item-list">
					<div class="item"
						v-for="(item, index) in items"
						:key="index"
					>
						<div class="td">{{ item.rowNumber }}</div>
						<div class="td">{{ item.joinDate | transDate }}</div>
						<div class="td">{{ item.businessName }}</div>
						<div class="td">{{ item.terminalName }}</div>
						<div class="td">{{ item.terminalCode }}</div>
						<div class="td">{{ item.terminalNumber }}</div>
						<div class="td"><div :class="{ active: item.status == 'Normal'}" @click="showPopup(item.pgTid)">{{ item.statusName }}</div></div>
					</div>
				</div>
				<div
					v-if="items.length <= 0"
					class="no-data"
				>
					No Data
				</div>
				
				<div class="text-center">
					<Pagination 
						:options="search"
					/>
				</div>
			</div>
		</div>

		<MerchantsTerminalPopupStatus
			v-if="itemStatus.show"
			:itemStatus="itemStatus"
			:codes="codes"
			
			@setOverlay="setOverlay"
			@setNotify="setNotify"
		/>
	</div>
</template>

<script>

	import { Axios } from '@/resources/axios/axios'
	import { date } from '@/resources/date/date'
	import { filter } from '@/resources/filter/filter'
	
	import Pagination from '@/components/Pagination'
	import MerchantsTerminalPopupStatus from '@/view/Merchants/Terminal/MerchantsTerminalPopupStatus'

	export default {
		name: 'PaymentsHistory'
		,props: ['user', 'codes']
		,components: { Pagination, MerchantsTerminalPopupStatus }
		,data: function(){
			return {
				program: {
					name: '단말기 목록'
				}
				,items: [
				]
				,itemStatus: {
					show: false
					,pgTid: ''
				}
				,search_default: {
					inquiryStartDate: '20200101'
					,inquiryEndDate: ''
					,keyword: ''
					,status: ''
					,posType: ''
				}
				,posType: [
					{ code: 'Normal', codeName: '해지'}
					,{ code: 'Termination', codeName: '해지'}
					,{ code: 'Termination', codeName: '해지'}
				]
				,search: {
				}
				,datePicker: {
					start: {
						view: false
					}
					,end: {
						view: false
					}
				}
				,dateType: 'c'
				,today: date.getToday()
			}
		}
		,filter: filter
		,methods: {
			getData: async function(){
				try{
					const result = await Axios({
						method: 'get'
						,url: '/merchants/terminal'
						,data: this.search
						,authorize: true
					})
					
					if(result.success){
						this.items = result.data.content.content
						
						this.$set(this.search, 'total_page', result.data.content.totalPages)
						this.$set(this.search, 'total_count', result.data.content.totalElements)
						this.$set(this.search, 'list_cnt', result.data.content.size)
					}else{
						this.$emit('setNotify', { type: 'error', message: result.message })
					}
				}catch(E){
					this.$emit('setNotify', { type: 'error', message: E })
				}
			}
			,clear: function(){
				this.setSearchDate('c')
				this.search = this.search_default
			}
			,setSearchDate: async function(type){
				this.dateType = type
				if(type == 'd'){
					this.search.inquiryStartDate = this.today
					this.search.inquiryEndDate = this.today
				}else if(type == 'w'){
					let dates = date.getWeeklyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}else if(type == 'm'){
					let dates = date.getMonthlyDate()
					this.search.inquiryStartDate = dates.start
					this.search.inquiryEndDate = dates.end
				}
				
				if(type != "c"){
					await this.getData()
				}
			}
			,showPopup: function(pgTid){
				this.itemStatus.pgTid = pgTid
				this.itemStatus.show = true
				this.$emit('setOverlay', true)
			}
			,setNotify: function({ type, message }){
				console.log('setNotify type: ' + message)
				this.$emit('setNotify', { type: type, message: message }) 
			}
			,setOverlay: function(){
				this.itemStatus.show = false
				this.$emit('setOverlay', false)
			}
		}
		,created: async function(){
			this.clear()
			await this.getData()
		}
		,watch: {
			'search.inquiryStartDate': {
				handler: function(call){
//					console.log('search.inquiryStartDate: ' + call)
					this.search.inquiryStartDate = call.replaceAll('-', '')
				}
			}
			,'search.inquiryEndDate': {
				handler: function(call){
//					console.log('search.inquiryEndDate: ' + call)
					this.search.inquiryEndDate = call.replaceAll('-', '')
				}
			}
		}
	}
</script>























